/*global define: true */
// identify.js may not be the best name. TODO: Rename this after we come up with and are happy with a better name.
mmf_modules.define('mmf/identify',[
    'jquery',
    'plugins/cookies',
    'libs/fingerprint',
    'utils/user'
], function (
    $,
    cookies,
    Fingerprint,
    mmf_user
) {
    'use strict';
    $(function () {
        // check cookie, set if not there ...
        if ($.cookies.get('mmfdid') === null) {
            $.cookies.set('mmfdid', new Fingerprint().get());
        }
        if ($.cookies.get('mmfuid') === null && mmf_user.user_id !== undefined) {
            $.cookies.set('mmfuid', mmf_user.id);
        }
    });
});
